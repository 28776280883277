<template>
  <div>
    <!------------ START: Variables field ------------>
    <VariablesField
      v-if="isVariablesField"
      v-model="$v.value.$model"
      :field="field"
      :field-wrapper="true"
      :show-toggle="true"
      @toggle-variables-field="toggleVariablesField"
    />
    <!------------ END: Variables field ------------>
    <!------------ START: FieldWrapper ------------>
    <FieldWrapper
      v-else
      :field="field"
      :variables-field-active="variablesFieldActive"
      @toggle-variables-field="toggleVariablesField"
    >
      <!------------ START: Range ------------>
      <v-slider
        v-if="!isVariablesField"
        v-model="$v.value.$model"
        v-bind="config"
        thumb-label
        hide-details
        :disabled="isDisabled"
      />
      <!------------ END: Range ------------>
    </FieldWrapper>
    <!------------ END: FieldWrapper ------------>
  </div>
</template>

<script>
import {
  base,
  eagerValidation,
  variablesField
} from "@/components/Tools/FormHelper/Helper/mixins";
import FieldWrapper from "@/components/Tools/FormHelper/Components/FieldWrapper";
import VariablesField from "@/components/Tools/FormHelper/Components/InputVariables";

export default {
  components: { FieldWrapper, VariablesField },
  mixins: [base, variablesField, eagerValidation],
  props: {},
  data() {
    return {
      showPassword: false,
      config: {
        min: this.field.min ?? 0,
        max: this.field.max ?? 100,
        step: this.field.step ?? 1,
        label: this.field.sliderLabel
          ? this.getSnippet(this.field.sliderLabel)
          : "",
        "prepend-icon": this.field.prependIcon,
        "append-icon": this.field.appendIcon
      }
    };
  },
  computed: {},
  mounted() {},
  methods: {}
};
</script>
